function Blog() {
  return (
    <div className="blog">
      <footer className="footer blog-footer">
        <div className="container">
          <div className="row">
            <strong className="f-logo">
              <a href="#">
                <img src="../images/site-logo.svg" alt="iAGENT PROTOCOL" />
              </a>
            </strong>
          </div>
        </div>
      </footer>
      <img
        className="blog-shadow-left"
        src="./images/shadow.png"
        alt="shadow"
      />
      <img
        className="blog-shadow-right"
        src="./images/shadow.png"
        alt="shadow"
      />

      <div className="blog-container">
        <p className="title">iAGENT BLOG</p>
        <div>
          {Array.from({ length: 10 }).map(() => {
            return (
              <div className="blog-card-container">
                <img src={"../images/blog-image.svg"} alt="" />
                <div>
                  <p className="title">
                    Revolutionizing Realms: The Role of GPUs in Gaming and
                    Beyond
                  </p>
                  <p className="desc">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.{" "}
                  </p>
                  <p className="date">19 AUGUST 2024</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <footer className="footer blog-footer">
        <div className="container">
          <div className="row">
            <strong className="f-logo">
              <a href="#">
                <img src="../images/site-logo.svg" alt="iAGENT PROTOCOL" />
              </a>
            </strong>
            <div className="links-area">
              <div className="col-holder">
                <strong className="title">Reach Out</strong>
                <ul className="footer-links">
                  <li>
                    <a
                      href="https://www.linkedin.com/company/iagent-protocol"
                      target="_blank"
                    >
                      LinkedIn
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/iAgentProtocol"
                      target="_blank"
                    >
                      Twitter
                    </a>
                  </li>
                  <li>
                    <a href="https://discord.gg/iagentprotocol" target="_blank">
                      Discord
                    </a>
                  </li>
                  <li>
                    <a href="#">Telegram</a>
                  </li>
                </ul>
              </div>
              <div className="col-holder">
                <strong className="title">Resources</strong>
                <ul className="footer-links">
                  <li>
                    <a href="/blog">Blog</a>
                  </li>
                  <li>
                    <a href="mailto:jamie@iagentpro.com">Careers</a>
                  </li>
                  <li>
                    <a href="https://docs.iagentpro.com/" target="_blank">
                      Docs
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://docs.iagentpro.com/official-links"
                      target="_blank"
                    >
                      Partners
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="posts-area">
              <strong className="title">Latest updates</strong>
              <div className="posts-wrap">
                <div className="post-holder">
                  <div className="image-holder">
                    <img src="./images/img-update.svg" alt="iAGENT PROTOCOL" />
                  </div>
                  <div className="text-holder">
                    <p>Coming Soon.</p>
                  </div>
                </div>
                <div className="post-holder">
                  <div className="image-holder">
                    <img src="./images/img-update.svg" alt="iAGENT PROTOCOL" />
                  </div>
                  <div className="text-holder">
                    <p>Coming Soon.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p>&copy;2024 iAgent Corporation (USA) | All rights reserved</p>
        </div>
      </footer>
    </div>
  );
}

export default Blog;
